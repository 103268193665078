import React from 'react';
import styled from 'styled-components';
import SocialMediaIconLink from 'components/Footer/SocialMediaIconLink';
import Triangle, { CornerTypes } from 'components/Triangle/Triangle';
import { theme } from 'styles/styled';
import { useTranslations } from 'settings/i18n';
import socialMediaLinks from 'settings/social-media-links';
import InstagramIcon from 'images/Icons/CustomInstagramIcon';
import TwitterIcon from 'images/Icons/CustomTwitterIcon';
import TelegramIcon from 'images/Icons/CustomTelegramIcon';
import YoutubeIcon from 'images/Icons/CustomYoutubeIcon';
import { useIsRtl } from 'hooks/useCurrentLocale';

import BuildDate from 'components/BuildDate/BuildDate';

import {
  MinimalContainer,
  MinimalFooter,
  MinimalFooterLinks,
  IconsWrapper,
  MinimalCopyright,
  CustomSocialMediaIconLink,
} from './styled';

const MinimalIconsWrapper = styled(IconsWrapper)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
`;

const FooterComponent = () => {
  const t = useTranslations();
  const isRtl = useIsRtl();

  return (
    <MinimalFooter>
      <Triangle
        width="100%"
        height="106px"
        zIndex={101}
        cornerType={CornerTypes['bottom-right']}
        color={theme.palette.primary}
        position={{ right: 0, top: '-105px' }}
      />
      <Triangle
        width="100%"
        height="36px"
        zIndex={101}
        showOnMobile
        cornerType={CornerTypes['bottom-right']}
        color={theme.palette.primary}
        position={{ right: 0, top: '-35px' }}
      />
      <MinimalContainer>
        <MinimalFooterLinks>
          <MinimalIconsWrapper>
            <SocialMediaIconLink href={socialMediaLinks.instagram}>
              <InstagramIcon />
            </SocialMediaIconLink>

            <CustomSocialMediaIconLink href={socialMediaLinks.twitter}>
              <TwitterIcon />
            </CustomSocialMediaIconLink>

            <CustomSocialMediaIconLink href={socialMediaLinks.telegram}>
              <TelegramIcon />
            </CustomSocialMediaIconLink>

            <CustomSocialMediaIconLink href={socialMediaLinks.youtube}>
              <YoutubeIcon />
            </CustomSocialMediaIconLink>
          </MinimalIconsWrapper>

          <MinimalCopyright isRtl={isRtl} color="light" weight="medium">
            © {t('allRightsReserved')}
          </MinimalCopyright>
        </MinimalFooterLinks>
      </MinimalContainer>
      <BuildDate />
    </MinimalFooter>
  );
};

export default FooterComponent;
