import React from 'react';

import MainLogo from 'images/Logo';
import useLinkCreator from 'hooks/useLinkCreator';

import {
  MinimalLayoutWrapper,
  Navigation,
  NavigationContent,
  LogoWrapper,
} from './styled';

interface LayoutProps {
  children: JSX.Element | React.ReactChildren[] | JSX.Element[];
}

const MinimalLayout = ({ children }: LayoutProps) => {
  const createLink = useLinkCreator();

  return (
    <MinimalLayoutWrapper>
      <Navigation>
        <NavigationContent>
          <LogoWrapper fixedHeader href={createLink('/')}>
            <MainLogo />
          </LogoWrapper>
        </NavigationContent>
      </Navigation>
      {children}
    </MinimalLayoutWrapper>
  );
};

export default MinimalLayout;
