import styled from 'styled-components';
import { theme } from 'styles/styled';

export const MinimalLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 100vh;
  height: 100%;
`;

export const Navigation = styled.nav`
  display: flex;
  max-width: 1360px;
  width: 100%;
`;

export const NavigationContent = styled.div`
  display: flex;
  width: 100%;
  margin-left: ${theme.dimensions.spacingBase16 * 2}px;
  margin-top: ${theme.dimensions.spacingBase16 * 2}px;

  @media ${theme.devices.large} {
    margin-top: ${theme.dimensions.spacingBase16 * 6.25}px;
    margin-left: ${theme.dimensions.spacingBase16 * 6.25}px;
  }

  @media ${theme.devices.xxlarge} {
    margin-left: 0;
  }
`;

export const LogoWrapper = styled.a<{ fixedHeader: boolean }>`
  width: 180px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
